<template>
  <div>
    <div class="d-flex align-items-center">
      <b-input
        v-model.trim="filter.patientFio"
        placeholder="Введите ФИО"
        class="mr-2"
        @keyup.native.enter="$emit('search')"
      />

      <b-button
        id="popover-filter"
        v-b-tooltip.hover
        :variant="filterSelected ? 'primary' : 'light'"
        :type="$const.PRIMARY_BUTTON"
        class="border border-primary px-1 mr-2"
        title="Дополнительные фильтры"
        @click="togglePopover"
      >
        <b-icon icon="filter" />
      </b-button>

      <b-popover
        custom-class="appeals-popover"
        :show.sync="isPopoverOpen"
        target="popover-filter"
        triggers="manual"
        placement="bottomleft"
      >
        <template #title>
          <div class="d-flex justify-content-end">
            <b-button
              variant="outline"
              :type="$const.PRIMARY_BUTTON"
              class="p-0"
              @click="isPopoverOpen = false"
            >
              <b-icon
                icon="x"
                variant="primary"
                aria-hidden="true"
              />
            </b-button>
          </div>
        </template>
        <custom-scrollbar>
          <div class="popover-inner p-2">
            <div>
              <div class="mb-3">
                <div class="mb-2 font-weight-bold">
                  Дата создания
                </div>
                <div class="d-flex">
                  <div class="mb-1">
                    <div class="small font-weight-bold mb-1">
                      От
                    </div>
                    <base-date-picker
                      v-model="filter.creationDateFrom"
                      :max-date="maxDate"
                      class="mb-1"
                      @keyup.native.enter="$emit('search')"
                    />
                  </div>
                  <div class="ml-2 mb-1">
                    <div class="small font-weight-bold mb-1">
                      До
                    </div>
                    <base-date-picker
                      v-model="filter.creationDateTo"
                      :max-date="maxDate"
                      @keyup.native.enter="$emit('search')"
                    />
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <div class="mb-2 font-weight-bold">
                  Цель обращения
                </div>
                <multiselect
                  v-model="filter.purpose"
                  :multiple="true"
                  :searchable="true"
                  :close-on-select="true"
                  :hide-selected="true"
                  label="title"
                  track-by="id"
                  select-label="Выбрать"
                  selected-label="Выбрано"
                  deselect-label="Нажмите, чтобы удалить"
                  placeholder="Выберите цель обращения"
                  :options="filteredPurposes"
                >
                  <span slot="noResult">Не удалось найти цели обращения</span>
                </multiselect>
              </div>
              <div class="mb-3">
                <div class="crm-form-block">
                  <div class="mb-2 font-weight-bold">
                    Категория обращения
                  </div>
                  <multiselect
                    v-model="filter.category"
                    :multiple="true"
                    :close-on-select="true"
                    :hide-selected="true"
                    label="name"
                    track-by="id"
                    :disabled="!filter.purpose.length"
                    select-label="Выбрать"
                    selected-label="Выбрано"
                    deselect-label="Нажмите, чтобы удалить"
                    placeholder="Выберите категорию обращения"

                    :options="categories"
                  >
                    <span slot="noResult">Не удалось найти категории обращения</span>
                  </multiselect>
                </div>
              </div>
              <div class="mb-3">
                <div class="crm-form-block">
                  <div class="mb-2 font-weight-bold">
                    Статус обращения
                  </div>
                  <multiselect
                    v-model="filter.status"
                    :multiple="true"
                    :close-on-select="true"
                    :hide-selected="true"
                    label="title"
                    track-by="id"
                    select-label="Выбрать"
                    selected-label="Выбрано"
                    deselect-label="Нажмите, чтобы удалить"
                    placeholder="Выберите статус обращения"
                    :options="APPEALS_STATUSES_TEXTS"
                  >
                    <span slot="noResult">По данному запросу не удалось найти статус обращения</span>
                  </multiselect>
                </div>
              </div>
              <div class="mb-3">
                <div class="crm-form-block">
                  <div class="mb-2 font-weight-bold">
                    Ответственный
                  </div>
                  <multiselect
                    v-model="filter.responsibleId"
                    :multiple="true"
                    :hide-selected="true"
                    :close-on-select="true"
                    label="displayName"
                    track-by="id"
                    select-label="Выбрать"
                    selected-label="Выбрано"
                    deselect-label="Нажмите, чтобы удалить"
                    placeholder="Выберите ответственного"
                    :options="filteredResponsibles"
                  >
                    <span slot="noResult">Не удалось найти ответственного</span>
                  </multiselect>
                </div>
              </div>
              <div class="mb-3">
                <div class="crm-form-block">
                  <div class="mb-2 font-weight-bold">
                    Клиника
                  </div>
                  <multiselect
                    v-model="filter.clinics"
                    :multiple="true"
                    :close-on-select="true"
                    label="name"
                    track-by="id"
                    select-label="Выбрать"
                    selected-label="Выбрано"
                    deselect-label="Нажмите, чтобы удалить"
                    placeholder="Выберите клинику"
                    :options="clinics"
                  >
                    <span slot="noResult">Клиники не найдены</span>
                  </multiselect>
                </div>
              </div>
              <!-- <base-async-select
              v-model="selectedClinic"
              :fetch-function="fetchClinics"
              class="crm-form-field crm-form-field-base-select"
              placeholder="Выберите клинику"
              label="Клиника"
              loading-title="Загрузка клиник"
              no-options-title="Клиники не найдены"
              @change="changeClinic"
            /> -->

              <div class="mb-3">
                <div class="crm-form-block">
                  <div class="mb-2 font-weight-bold">
                    Страховая компания
                  </div>
                  <multiselect
                    v-model="filter.insuranceCompanyId"
                    :multiple="true"
                    :hide-selected="true"
                    :close-on-select="true"
                    label="title"
                    track-by="id"
                    select-label="Выбрать"
                    selected-label="Выбрано"
                    deselect-label="Нажмите, чтобы удалить"
                    placeholder="Выберите компанию"
                    :options="insuranceCompanies"
                  >
                    <span slot="noResult">Страховые компании не найдены</span>
                  </multiselect>
                </div>
              </div>

              <div class="mb-3">
                <div class="crm-form-block">
                  <div class="mb-2 font-weight-bold">
                    Страховая программа
                  </div>
                  <multiselect
                    v-model="filter.insuranceProgramId"
                    :multiple="true"
                    :close-on-select="true"
                    label="name"
                    track-by="id"
                    select-label="Выбрать"
                    selected-label="Выбрано"
                    deselect-label="Нажмите, чтобы удалить"
                    placeholder="Выберите программу"
                    :options="insurancePrograms"
                  >
                    <span slot="noResult">Страховые программы не найдены</span>
                  </multiselect>
                </div>
              </div>

              <div class="mb-3">
                <div class="crm-form-block">
                  <div class="mb-2 font-weight-bold">
                    Страховая подпрограмма
                  </div>
                  <multiselect
                    v-model="filter.insuranceSubprogramId"
                    :multiple="true"
                    :close-on-select="true"
                    label="name"
                    track-by="id"
                    :disabled="!filter.insuranceProgramId?.length"
                    select-label="Выбрать"
                    selected-label="Выбрано"
                    deselect-label="Нажмите, чтобы удалить"
                    placeholder="Выберите подпрограмму"
                    :options="insuranceSubprograms"
                  >
                    <span slot="noResult">Страховые подпрограммы не найдены</span>
                  </multiselect>
                </div>
              </div>

              <div class="d-flex align-items-center">
                <b-button
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  @click="applyFilter"
                >
                  Применить
                </b-button>
              </div>
            </div>
          </div>
        </custom-scrollbar>
      </b-popover>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        @click="$emit('search')"
      >
        <b-icon icon="search" />
      </b-button>
    </div>
    <div class="d-flex justify-content-between w-100 mt-2">
      <b-input
        v-model.trim="filter.phoneNumber"
        size="sm"
        placeholder="Номер телефона"
        class="mr-2"
        @keyup.native.enter="$emit('search')"
      />
      <v-select
        v-model="filter.status"
        :reduce="clinic => [clinic]"
        :options="APPEALS_STATUSES_TEXTS"
        label="title"
        placeholder="Статус"
        :clearable="false"
        class="crm-select person-select mr-2"
      />

      <b-input
        v-model.trim="filter.policyNumber"
        size="sm"
        placeholder="Номер полиса"
        class="mr-2"
        @keyup.native.enter="$emit('search')"
      />
      <b-input
        v-model.trim="filter.id"
        size="sm"
        placeholder="ID обращения"
        class="mr-2"
        @keyup.native.enter="$emit('search')"
      />

      <b-button
        v-if="checkFeatureAccess({ name: 'Обращения - Экспорт', url: '/appeals' })"
        variant="outline-primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        @click="openExportModal"
      >
        Экспорт
      </b-button>
    </div>
    <div class="d-flex justify-content-between mt-2">
      <b-form-checkbox
        v-model="filter.hasReconciliation"
        switch
        size="sm"
        @change="$emit('search')"
      >
        Показывать только обращения с согласованиями
      </b-form-checkbox>
      <b-button
        variant="outline-primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        class="border-0"
        @click="resetFilter"
      >
        Сбросить фильтры
      </b-button>
    </div>
  </div>
</template>

<script>
import Bus from '@/eventBus';

import { mixinRoles } from '@/mixins';
import { ROLES } from '@/helpers/roles';
import {
  APPEALS_STATUSES_TEXTS,
  APPEALS_PURPOSES_TEXTS,
} from '@/services/appeals/appeals.const';
import { BaseDatePicker } from '@/components/base';
import { accountService, appealsService, clinicService } from '@/services';
import { showErrorMessageGetUsers } from '@/helpers/messages';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'AppealsSearchPanel',
  components: {
    BaseDatePicker,
  },
  mixins: [mixinRoles],
  model: {
    prop: 'filter',
    event: 'change',
  },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
    closeFilterPopoverTrigger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPopoverOpen: false,
      categoriesAndTypesLoading: false,
      categories: [],
      subtypes: [],
      APPEALS_PURPOSES_TEXTS,
      APPEALS_STATUSES_TEXTS,
      userAccount: null,
      typeId: null,
      maxDate: new Date(),
      purposes: [
        { id: 0, title: 'Информационное' },
        { id: 1, title: 'Чекап' },
        { id: 2, title: 'Лечение / плановая госпитализация' },
        { id: 3, title: 'Экстренная госпитализация' },
      ],
      filterKeys: [
        'creationDateFrom',
        'creationDateTo',
        'purpose',
        'type',
        'status',
        'responsibleId',
        'clinics',
        'insuranceCompanyId',
        'insuranceProgramId',
        'insuranceSubprogramId',
        'category',
      ],
      responsibles: [],
      insuranceData: [],
      insuranceCompanies: [],
      insurancePrograms: [],
      insuranceSubprograms: [],
      clinics: [],
    };
  },
  computed: {
    purposeId() {
      return this.filter.purpose;
    },
    filteredPurposes() {
      return this.userAccount.appealPurposes?.length
        ? APPEALS_PURPOSES_TEXTS
          .filter((purpose) => this.userAccount.appealPurposes.some((el) => el === purpose.id))
        : APPEALS_PURPOSES_TEXTS;
    },
    selectedCategory() {
      return this.categories.find((item) => item.id === this.filter.category);
    },
    filterSelected() {
      const filters = this.filterKeys.map((item) => this.filter[item]);
      return Object.values(filters).some((item) => item || item === 0);
    },
    filteredResponsibles() {
      const {
        OPERATOR, TECH_OPERATOR, MEDICAL_CURATOR, DIS_MONITORING_OPERATOR,
      } = ROLES;
      const validateRoles = [OPERATOR, TECH_OPERATOR, MEDICAL_CURATOR, DIS_MONITORING_OPERATOR];
      return this.responsibles.filter((item) => validateRoles.includes(item.roleName));
    },
  },
  watch: {
    async purposeId() {
      await this.fetchCategoriesAndTypes();
      // this.clearFieldsOnChangePurpose();
    },
    'filter.purpose': {
      handler() {
        this.filter.category = null;
        this.filter.category = [];
      },
    },
    'filter.insuranceCompanyId': {
      handler() {
        this.filter.insuranceProgramId = null;
        this.filter.insuranceSubprogramId = null;
        this.insurancePrograms = [];
        this.insuranceSubprograms = [];
        this.setInsuranceEntities();
      },
    },
    'filter.insuranceProgramId': {
      handler() {
        this.filter.insuranceSubprogramId = null;
        this.insuranceSubprograms = [];
        this.setInsuranceEntities();
      },
    },
    'form.categoryId': {
      handler() {
        this.clearFieldsOnChangeCategory();
      },
    },
  },
  async created() {
    if (this.filter.purpose?.length) {
      this.fetchCategoriesAndTypes();
    }
    this.userAccount = this.$store.state.Auth.user;
    this.filter.purpose = [];
    this.responsibles = await this.fetchResponsibles();
    this.insuranceData = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
    this.insuranceCompanies = this.insuranceData.map(({ company: { id, title } }) => ({ id, title }));
    this.clinics = await this.fetchClinics();
    this.setInsuranceEntities();
  },
  mounted() {
    // Находится в некоем скролл-компоненте. Использую класс __panel для нахождения данного блока.
    const scrollElements = document.getElementsByClassName('__panel');

    if (scrollElements.length) {
      scrollElements[0].addEventListener('scroll', this.onScrollPage);
    }
  },
  methods: {
    async fetchCategoriesAndTypes() {
      this.categoriesAndTypesLoading = true;

      try {
        const filteredPurposes = this.filter.purpose?.map((item) => item.id);
        const data = await this.fetchNewTypesAppeals(filteredPurposes);
        this.categories = data;
        return data;
      } finally {
        this.categoriesAndTypesLoading = false;
      }
    },
    async fetchNewTypesAppeals(obj) {
      try {
        const newTypes = await appealsService.getTypes(obj);
        return newTypes;
      } catch (err) {
        console.log(err);
      }
    },
    applyFilter() {
      this.$emit('search');
      this.isPopoverOpen = false;
    },
    onScrollPage() {
      this.isPopoverOpen = false;
    },
    togglePopover() {
      this.isPopoverOpen = !this.isPopoverOpen;
    },
    handleScroll() {
      this.isPopoverOpen = false;
    },
    resetFilter() {
      Bus.$emit('appeals:reset-filter');
      this.$emit('search');
    },
    async fetchResponsibles() {
      try {
        const responsibles = await accountService.getUsers({ take: 1000 });
        return responsibles;
      } catch (e) {
        console.warn(e);
        showErrorMessageGetUsers();
      }
    },
    async fetchClinics() {
      const data = await clinicService.getListNames();
      return data;
    },
    setInsuranceEntities() {
      if (!this.filter.insuranceCompanyId) return;
      const filteredCompanyObjects = this.insuranceData.filter((dataObject) => {
        const companyId = dataObject.company.id;
        return this.filter.insuranceCompanyId.some((company) => company.id === companyId);
      });

      const programs = filteredCompanyObjects
        .flatMap((dataObject) => dataObject.programsWithSubprograms
          .map(({ program: { id, name } }) => ({ id, name })));
      this.insurancePrograms = programs;

      if (!this.filter.insuranceProgramId) return;

      const subprograms = filteredCompanyObjects
        .flatMap((dataObject) => dataObject.programsWithSubprograms
          .flatMap(({ subPrograms }) => subPrograms.map(({ subprogramId, name }) => ({ id: subprogramId, name }))));
      this.insuranceSubprograms = subprograms;
    },
    changeSubrogram() {},
    openExportModal() {
      uiService.showModal(MODALS.APPEAL_EXPORT_MODAL, {
        name: 'AppealsExportModal',
        props: {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.crm-icon-search {
  path {
    fill: #ffffff;
  }
}

::v-deep.crm-filter-button {
  &.active {
    background: $blue;

    .crm-icon-filter path{
      fill: #fff;
    }
  }

  .crm-icon-filter path{
    fill: $blue,
  }

}

::v-deep.crm-filter-field {
  &.crm-align-center {
    display: flex;
    align-items: center;
  }

  &--small {
    width: 155px;
  }

  &--reset {
    margin-left: auto!important;
    .reset_button {
      margin-left: auto;
      padding: 0;
      font-size: 14px;
      width: unset;
    }
  }

  &.field-gray-bg {
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
}

::v-deep.crm-wrap-block.main-field {
  width: calc(100% - 174px);
  flex-grow: unset;
}

.crm-form-field-wrapper {
  display: flex;
  align-items: center;

  .crm-form-divider {
    width: 30px;
    height: 1px;
    background-color: #323232;
    margin: 0 10px;
  }
}

.crm-form-date-picker {
  width: 160px;
}

::v-deep.crm-form-field {
    &.crm-form-field-base-select {
      max-width: 370px;
    }

    & + .crm-form-field {
      margin-top: 20px;
    }

  .vs__selected {
    max-width: 300px;
  }
}

.apply-button {
  margin: 20px auto 0;
}

.popover-inner {
  max-height: 450px;
  margin-right: 10px;
}

.popover {
  width: 500px !important;
  max-width: unset !important;
}
.crm-select{
  background: #fff;
  border: 1px solid rgb(216, 208, 208);
  border-radius: 4px;
  height: 31px;
  width: 100%;
}
::v-deep.person-select {
  .vs__dropdown-toggle {
    height: 31px;
  }
  .vs__selected {
    line-height: 18px;
  }
}
.multiselect__tag {
 background: #323232;
}
</style>
