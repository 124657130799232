<template>
  <div
    :class="{
      'appeal-policy-item_active': active,
      'appeal-policy-item_error': error,
      'appeal-policy-item_disabled': disabled,
    }"
    class="appeal-policy-item d-flex justify-content-between p-3 border rounded bg-light"
  >
    <div class="">
      <div class="mb-1 font-weight-bold">
        {{ policy.lastName }} {{ policy.firstName }} {{ policy.middleName }}
      </div>
      <div class="mb-1">
        {{ policy.ensurerName }}
      </div>
      <div class="mb-1">
        № {{ policy.policyId }}
      </div>
      <template v-if="!isLoading">
        <div class="mb-1">
          «{{ companyData.name }}»
        </div>
        <div class="mb-1">
          «{{ programData.name }}»
        </div>
        <div class="mb-1">
          «{{ subprogramData.name }}»
        </div>
      </template>
      <template v-else>
        <b-skeleton
          animation="wave"
          width="52%"
        />
        <b-skeleton
          animation="wave"
          width="45%"
        />
        <b-skeleton
          animation="wave"
          width="70%"
        />
      </template>
      <div>
        {{ expirationDate }}
      </div>
    </div>

    <div class="d-flex flex-column justify-content-between align-items-end">
      <div class="d-flex flex-column">
        <b-button
          v-if="showCreateLetterGuarantee"
          v-b-tooltip.hover.left
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="p-1 w-30px mb-1"
          title="Создать гп"
          @click="openIndemnityModal($event, false)"
        >
          <b-icon icon="file-earmark-lock2" />
        </b-button>
        <b-button
          v-if="showCreateDirection"
          v-b-tooltip.hover.left
          variant="success"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="p-1 w-30px mb-1"
          title="Создать направление"
          @click="openIndemnityModal($event, true)"
        >
          <b-icon icon="file-earmark-medical" />
        </b-button>
        <b-button
          v-if="showDetailInfo"
          v-b-tooltip.hover.left
          variant="info"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="p-1 w-30px"
          title="Детальная информация"
          @click="openPolicyDetail($event)"
        >
          <b-icon icon="info-circle" />
        </b-button>
      </div>

      <b-button
        v-if="showSelectButton"
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        :disabled="disabled"
        class="p-1 mt-3 text-nowrap"
        @click="$emit('select-policy')"
      >
        {{ active ? 'Полис выбран' : 'Выбрать' }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from '@evd3v/date-fns';

export default {
  name: 'AppealPolicyItem',

  props: {
    policy: {
      type: Object,
      default: () => ({}),
    },
    person: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showCreateLetterGuarantee: {
      type: Boolean,
      default: true,
    },
    showCreateDirection: {
      type: Boolean,
      default: true,
    },
    showDetailInfo: {
      type: Boolean,
      default: true,
    },
    showSelectButton: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    companyData: null,
    programData: null,
    subprogramData: null,
    isLoading: false,
  }),
  computed: {
    expirationDate() {
      try {
        const { startDate, endDate } = this.policy;

        if (!startDate) {
          return `до ${this.parseData(endDate)}`;
        }

        return `${this.parseData(startDate)} - ${this.parseData(endDate)}`;
      } catch (e) {
        console.error(e);
      }
      return '';
    },
    subProgramId() {
      return this.policy.subProgramV2Id || this.policy.subprogramId;
    },
  },

  created() {
    this.fetchSubprogram();
  },

  methods: {
    async fetchSubprogram() {
      this.isLoading = true;

      try {
        const { company, program, subProgram } = await this.$store.dispatch(this.$types.SUBPROGRAM_FETCH, this.subProgramId);

        this.companyData = company;
        this.programData = program;
        this.subprogramData = subProgram;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    bootstrapModalPositionChange(val) {
      const actualElements = document.querySelectorAll('div[id^="__BVID"]');
      actualElements[0].style.zIndex = val;
    },
    openIndemnityModal(event, isReferral) {
      event.stopPropagation();

      const { id: policyId } = this.policy;

      const subProgramId = this.policy.subProgramV2Id ?? this.policy.subprogramId;
      const personId = this.person.id;

      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'IndemnityModal',
        props: {
          personId, policyId, subProgramId, isReferral,
        },
      });
    },
    openPolicyDetail() {
      this.$store.commit(this.$types.PATIENT_ACTIVE_POLICY_ID_SET, this.policy.id);
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PatientDetailModal',
        props: { patientId: this.person.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.appeal-policy-item {

  &_disabled {
    opacity: 0.6;
  }

  &_active {
    opacity: 1;
    border: 1px solid #007bff !important;
    background-color: #ebf5ffc2 !important;
  }
  &_error {
    background-color: #fff6fa !important;
    border: 1px solid #f9a3a3 !important;
  }
}
.w-30px {
  width: 30px !important;
}
</style>
